@use '../../assets/styles/common' as *;

$mobile-header-vertical-offset: 18px;
$mobile-horizontal-offset: 16px;

.landing-header {
  position: fixed;
  z-index: 2;
  width: stretch;
  padding: 29px 80px;
  background-color: var(--landing-bg-yellow);
  transition: background-color 200ms linear;
}

.narrow-icon {
  min-width: 47px;
}

.menu-item {
  display: flex;
  align-items: center;
  color: var(--text-lightest);
  cursor: pointer;

  &.active {
    color: var(--text-primary);
  }

  &:hover {
    color: var(--text-secondary);
  }

  &:active {
    color: var(--text-primary);
  }
}

.action-buttons {
  &-mobile {
    width: 100%;

    a {
      width: 100%;
    }
  }

  a {
    padding: 9.5px 34px;
  }

  a:nth-of-type(2) {
    padding: 9.5px 36px 8.5px;
  }
}

.header-button {
  margin: 0;
}

.outlined-dropdown {
  border-radius: 20px;

  svg {
    width: 22px;
    height: 22px;
  }

  &-mobile {
    border-radius: 22px;

    svg {
      width: 26px;
      height: 26px;
    }
  }

  &:hover {
    box-shadow: 0 4px 15px 0 rgb(102 102 102 / 16%);
  }

  :global(.MuiSelect-icon) {
    display: none;
  }

  :global(.MuiSelect-select) {
    display: flex;
    padding: 8px !important;
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: 0;
  }

  &:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border: 0;
  }
}

.mobile {
  &-menu-wrap {
    position: fixed;
    z-index: 2;
    width: calc(100% - ($mobile-horizontal-offset * 2));
    height: 0;
    padding: 0 $mobile-horizontal-offset;
    background-color: var(--global-white);

    &-opened {
      height: 100%;
    }
  }

  &-landing-header {
    position: fixed;
    z-index: 2;
    width: inherit;
    margin-left: -$mobile-horizontal-offset;
    padding: $mobile-header-vertical-offset $mobile-horizontal-offset;
  }

  &-menu-content {
    height: inherit;
    padding: calc($brand-icon-height + $mobile-header-vertical-offset * 2 + 80px) 0 100px;
    overflow: auto;
  }
}
