.stepper {
  width: 100%;

  :global {
    .MuiStepConnector-root {
      .MuiStepConnector-line {
        border-color: var(--primary-main);
      }

      &.Mui-disabled {
        .MuiStepConnector-line {
          border-color: var(--global-white);
        }
      }
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      color: var(--global-white);

      .MuiStepIcon-text {
        color: var(--text-lightest);
        font-weight: 500;
        font-size: 14px;
        font-style: normal;
        line-height: 120%;
        letter-spacing: -0.266px;
        fill: unset;
      }

      &.Mui-active {
        color: var(--primary-main);

        .MuiStepIcon-text {
          color: var(--global-white);
        }
      }

      &.Mui-completed {
        color: var(--primary-main);
        background-color: var(--global-white);
        border-radius: 12px;
      }
    }

    .MuiStepLabel-labelContainer .MuiStepLabel-label {
      margin-top: 4px;

      .MuiTypography-root {
        color: var(--text-lightest);
      }

      &.Mui-active {
        .MuiTypography-root {
          color: var(--primary-main);
        }
      }

      &.Mui-completed {
        .MuiTypography-root {
          color: var(--primary-main);
        }
      }
    }
  }
}
