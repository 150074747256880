@use '../../assets/styles/common';

.loader-wrap {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.game-settings-wrap {
  height: inherit;
  background-color: var(--gray-bg-2);
}

.game-settings {
  width: 80%;
  max-width: 720px;
  margin-top: -144px;
}

.game-settings-form {
  padding: 40px 54px 32px;
  background: var(--global-white);
  border-radius: 44px;
  box-shadow: 0 4px 15px 0 rgb(102 102 102 / 16%);
}

.setting {
  width: 100%;
}

.settings-header {
  position: relative;
  margin-bottom: -20px;

  h2 {
    position: absolute;
    bottom: 35px;
  }
}

.start-game-button {
  align-self: center;
  width: 250px;
}

.carousel-form-wrap {
  width: 100%;
}

.form-wrap {
  width: 100%;
  padding: 16px;
  background: var(--gray-bg-1);
  border-radius: 16px;

  .arrow-drop-down-icon {
    right: 12px;
    width: 10px;
    fill: var(--gray-icon);
  }

  .dropdown {
    width: stretch;
  }

  :global {
    .MuiInputBase-root.MuiInputBase-root {
      padding: 0;
      background: var(--global-white);
      border: 1px solid var(--gray-bg-3);
      border-radius: 8px;

      input {
        padding: 7px 4px 7px 12px;
      }
    }

    .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
      right: 12px;
      display: flex;
      height: stretch;
      margin-bottom: 4px;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiButtonBase-root {
      padding: 0;

      &:hover {
        background: none;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.cards-number {
  height: 39px;
  font-size: 16px;

  svg {
    width: 10px;
    margin-top: 3px;
    fill: var(--gray-icon);
  }

  :global {
    .MuiSelect-select {
      padding: 0 0 0 12px;
    }
  }
}

.setting-head {
  svg {
    width: 16px;
    height: 16px;
    fill: var(--gray-icon);

    &:hover {
      fill: var(--primary-main);
    }
  }
}

.computer-grade {
  width: 100%;
}

.computer-grade-slider-wrap {
  height: 14px;
  padding: 0 7px;
  background-color: var(--global-white);
  border: 1px solid var(--gray-bg-3);
  border-radius: 7px;
}

.computer-grade-slider {
  :global {
    .MuiSlider-rail {
      height: 8px;
      padding-right: 4px;
      background: var(--primary-light);
      border-radius: 8px;
    }

    .MuiSlider-track {
      height: 8px;
      margin-left: -4px;
      background-color: var(--primary-main);
      border: 0;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }

    .MuiSlider-mark {
      width: 2px;
      height: 4px;
      background-color: var(--global-white);
      border-radius: 0;
      opacity: 1;
    }
  }
}

.custom-paper {
  :global {
    .MuiAutocomplete-listbox {
      overflow: unset;
    }
  }
}

.player-settings {
  width: stretch;

  .add-player-button :global {
    .MuiButton-startIcon {
      margin-left: 0;
    }
  }

  .player-inputs-wrap {
    flex-wrap: wrap;
    width: inherit;

    .player-input {
      flex: 0 0 calc(50% - 8px);

      :global {
        .MuiInputBase-root {
          padding: 0 8px 0 12px;

          input {
            padding: 7px 0;
          }
        }

        .MuiInputAdornment-root {
          color: unset;
        }
      }
    }
  }

  .add-player-button {
    color: var(--primary-main);

    svg {
      font-size: 24px;
    }

    &:hover {
      color: var(--primary-main);

      svg {
        font-size: 24px;

        :last-child {
          stroke: var(--primary-main);
        }
      }
    }
  }

  .remove-player-button {
    margin-right: 0;

    svg:hover {
      fill: var(--input-error);
    }
  }
}
