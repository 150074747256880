.top-panel-wrap {
  padding: 20px;

  &.mobile {
    padding: 0;

    .top-panel {
      padding: 14px;
      border-radius: 0;
    }
  }

  .top-panel {
    box-sizing: border-box;
    width: stretch;
    min-height: 73px;
    padding: 17px 20px;
    background: var(--primary-light);
    border-radius: 24px;
  }
}
