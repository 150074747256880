.item-wrap {
  width: inherit;
}

.item {
  gap: 8px;
  box-sizing: border-box;
  width: inherit;
  padding: 24px 16px;
  color: var(--text-primary);
  border: var(--gray-bg-3) solid 0;
  border-bottom-width: 1px;
  cursor: pointer;

  .icon-wrap {
    display: flex;
  }

  .title {
    margin: 0;
  }

  svg {
    width: 24px;
    fill: var(--primary-main);
  }

  &.active {
    color: var(--global-white);
    background-color: var(--primary-main);

    svg {
      fill: var(--global-white);
    }
  }

  &:active {
    background-color: var(--primary-dark);

    svg {
      fill: var(--global-white);
    }
  }
}
