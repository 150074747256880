.player-wrap {
  box-sizing: border-box;
  background-color: var(--gray-bg-2);
  border: 2px solid transparent;
  border-radius: 8px;

  .player {
    padding: 7px 12px;
    background: var(--gray-bg-1);
    border-radius: 7px 0 0 7px;
  }

  .player-name {
    min-width: 28px;
    max-width: 80px;
    min-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: text;
  }

  .score-wrap {
    width: 26px;
    padding: 6px 8.5px;
    text-align: center;

    .score {
      color: var(--text-secondary);
    }
  }

  &.active {
    background-color: var(--primary-main);

    .score-wrap .score {
      color: var(--global-white);
    }
  }
}
