@use '../../assets/styles/stepper';

.target,
.trace {
  :global(.MuiStepConnector-line) {
    margin-top: -2px;
    border-top-width: 4px;
    border-top-style: dotted;
  }
}
