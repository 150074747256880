body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  color: var(--text-primary);
  font-family: Montserrat, sans-serif;
}

#root {
  position: absolute;
  width: inherit;
  height: inherit;
  min-height: inherit;
  overflow-y: auto;
}

input,
select,
textarea {
  font-size: 16px !important;
}
