@use '../../assets/styles/common';

.tab-panel {
  width: stretch;
  padding: 0;
}

.achievements-table {
  border: 2px solid var(--gray-bg-2);
  border-radius: 24px;

  th {
    color: var(--text-lightest);
    background-color: var(--gray-bg-1);
  }

  td {
    padding: 16px 24px;
    border: 0;
  }

  .top-cell {
    font-size: 0;

    svg {
      height: 21px;
    }
  }
}

.button-tab-set {
  padding: 6px;
  background-color: var(--primary-lightest);
  border-radius: 24px;

  &:hover {
    box-shadow: 0 4px 15px 0 rgb(102 102 102 / 16%);
  }
}

.button-tab {
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: var(--text-lightest);
  border-radius: 16px;

  &:hover {
    color: var(--text-primary);
    background: none;
  }

  &.active {
    color: var(--global-white);
    background-color: var(--primary-main);
  }
}
