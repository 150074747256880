@use '../../assets/styles/common';

.wrapper {
  height: stretch;
  margin-top: -60px;

  :global(.MuiTypography-root) {
    margin: 0;
  }

  :global(.MuiButton-root) {
    width: fit-content;
    margin: 0;
    padding-right: 60px;
    padding-left: 60px;
  }
}
