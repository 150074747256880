.animated-item {
  padding: 24px;
  border-radius: 24px;
}

.static-items {
  padding: 0;

  .static-item {
    padding: 16px;
    border-radius: 24px;

    svg {
      width: 24px;
      min-width: 24px;
      fill: var(--text-secondary);
    }
  }
}

.animated-items {
  padding: 40px 0;

  .animated-line {
    .animated-item.animated-item {
      transition: 0.3s;
      transition-timing-function: ease-out;

      svg {
        width: 24px;
        min-width: 24px;
        fill: var(--text-secondary);
      }
    }

    &:nth-of-type(1) {
      .animated-item:nth-of-type(1) {
        transform: rotate(-3.456deg);
      }

      .animated-item:nth-of-type(2) {
        transform: rotate(-7.703deg);
      }
    }

    &:nth-of-type(2) {
      .animated-item:nth-of-type(1) {
        transform: rotate(7.882deg);
      }

      .animated-item:nth-of-type(2) {
        transform: rotate(5.515deg);
      }
    }

    &:nth-of-type(3) {
      .animated-item:nth-of-type(1) {
        transform: rotate(-6.537deg);
      }
    }
  }

  &:hover {
    .animated-line {
      .animated-item.animated-item {
        transform: rotate(0);
      }
    }
  }
}
