@use '../../assets/styles/common';

.copyright {
  position: relative;
  box-sizing: border-box;
  padding: 24px;
  text-align: center;
  background-color: var(--primary-lightest);
  border-radius: 24px;

  .author {
    align-self: center;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  &.aside {
    position: fixed;
    max-width: 363px;
    margin-right: 32px;
  }
}

.link {
  color: var(--primary-main);
  text-decoration: none;
}
