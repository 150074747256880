.donate-wrap {
  position: fixed;
  right: 56px;
  bottom: 56px;

  &-mobile {
    right: 36px;
    bottom: 76px;
  }
}

.donate-button {
  padding: 10px;
  background-color: var(--global-white);
  cursor: pointer;

  .donate-icon {
    width: 54px;
    height: 54px;
    transition: fill 300ms linear;
    fill: rgb(0 0 0 / 70%);
  }

  &:hover {
    background-color: var(--gray-bg-3);

    .donate-icon {
      fill: black;
    }
  }
}

.close-icon {
  position: absolute;
  top: -12px;
  right: -24px;
  cursor: pointer;
  transition: fill 300ms linear;
  fill: rgb(0 0 0 / 0%);

  &-mobile {
    fill: rgb(0 0 0 / 70%);
  }

  &:hover {
    fill: rgb(0 0 0 / 70%);
  }
}

.donate-button:hover + .close-icon {
  fill: rgb(0 0 0 / 70%);
}
