@use '../../assets/styles/common';

.playground-wrap {
  position: relative;
  width: stretch;
  height: stretch;
}

.playground {
  display: flex;
  flex-direction: column;
  padding: var(--playground-padding);
  overflow: hidden;
}

.cards-box {
  display: flex;
  flex-wrap: wrap;
}

.hint {
  height: inherit;
  margin-top: calc(var(--playground-padding) * -2);
}

.stop-game-button {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 130px;
  margin: 20px;
}
