:root {
  /* Global Gradient Palette */
  --global-gradient-light-blue-purple: linear-gradient(6.02deg, #0041c2 0%, #8b42ff 100%);
  --global-gradient-light-purple-cyan: linear-gradient(6.02deg, #6000f0 0%, #07b6d5 100%);
  --global-gradient-light-purple-blue: linear-gradient(6.02deg, #6000f0 0%, #3079f8 100%);
  --golden-achievement: #ffd700;
  --silver-achievement: #c0c0c0;
  --bronze-achievement: #cd7f32;
  --card-background: #fffbd9;
  --card-shadow: #52525b;
  --global-white: #fff;
  --danger-main: #f93950;
  --danger-dark: #720210;
  --primary-main: #394dff;
  --primary-dark: #0016d9;
  --primary-light: #d4dbff;
  --primary-lightest: #f5faff;
  --gray-bg-1: #f7f8f8;
  --gray-bg-2: #f2f2f2;
  --gray-bg-3: #e5e5e6;
  --gray-icon: #a4a5aa;
  --text-lightest: #5e5f61;
  --text-primary: #1b202b;
  --text-secondary: #3d3e3e;
  --gradient-1-dark: linear-gradient(225deg, #10afe5 0%, #115ff1 100%);
  --hex-1-dark: #1289ea;
  --gradient-1-light: #99dff6;
  --gradient-2-dark: linear-gradient(215deg, #f03f5f 0%, #ff3666 100%);
  --hex-2-dark: #f63a62;
  --gradient-2-light: #fabbd2;
  --gradient-3-dark: linear-gradient(220deg, #18cf8b 4.2%, #00c1b6 100%);
  --hex-3-dark: #0cc99d;
  --gradient-3-light: #b0f1d9;
  --gradient-4-dark: linear-gradient(224deg, #9b5dea 1.22%, #6b43f2 100%);
  --hex-4-dark: #985bea;
  --gradient-4-light: #e4a5f3;
  --gradient-4-lighest: #f4e4f8;
  --gradient-5-dark: linear-gradient(220deg, #7573e5 0%, #394cf1 100%);
  --hex-5-dark: #3c4ef0;
  --gradient-5-light: #c7bafa;
  --input-error: #d32f2fff;
  --feature-bg-1: #e3f1fd;
  --feature-bg-2: #fde2e7;
  --feature-bg-3: #eee4fc;
  --feature-bg-4: #e2fdf7;
  --feature-bg-5: #fff6e1;
  --landing-bg-yellow: #ffe6a4;
  --landing-bg-blue: #f5f5ff;
  --dd-menu-item-light: #1976d214;
}
