@use '../../assets/styles/common';

$action-vertical-offset: 80px;
$action-horizontal-offset: 40px;
$action-horizontal-offset-mobile: 16px;
$horizontal-offset: 80px;
$horizontal-offset-mobile: 16px;

.landing-page {
  background-color: var(--landing-bg-yellow);

  .landing-body {
    margin-top: 98px;
    background-color: var(--global-white);
  }

  .welcome-section {
    height: 591px;
    padding: 92px $horizontal-offset 0;
    background-color: var(--landing-bg-yellow);
  }

  .welcome-section-content {
    position: absolute;
    z-index: 1;
    width: fit-content;
    margin: 0 $horizontal-offset;
  }

  .welcome-section-internal {
    text-align: center;
  }

  .welcome-section-text {
    color: var(--text-secondary);
  }

  .play-now-button {
    align-self: center;
    width: 171px;
  }

  .carousel {
    margin-top: -111px;
    padding-bottom: 32px;
  }

  .skills-section {
    padding: 140px 80px;
    background-color: var(--global-white);
  }

  .skills-section-content {
    z-index: 1;

    :nth-child(1) {
      max-width: 415px;

      svg {
        width: auto;
        margin-bottom: -54px;
      }
    }

    :nth-child(2) {
      width: 428px;
    }
  }

  .features-section {
    padding: 140px 80px;
  }

  .how-to-play-section {
    padding: 140px 80px;
    background-color: var(--landing-bg-blue);

    .how-to-play-left-section {
      width: 520px;

      .step-icon {
        min-width: 48px;
        margin-top: -10px;
        fill: var(--hex-4-dark);
      }

      .arrow-icon {
        position: absolute;
        margin-top: 48px;
        fill: var(--hex-4-dark);
      }
    }

    .game-demo-mp4 {
      width: 800px;
      border: 5px solid var(--global-white);
      border-radius: 16px;
    }
  }

  .action-section {
    padding: 140px 80px;

    .action {
      position: relative;
      flex: 1 1 0px;
      padding: $action-vertical-offset $action-horizontal-offset;
      color: var(--global-white);
      background-color: var(--primary-main);
      border: 1px solid var(--primary-main);
      border-radius: 24px;

      &-inverse {
        color: var(--primary-main);
        background-color: var(--global-white);
      }

      &-content {
        z-index: 1;
      }

      a {
        width: fit-content;
        padding-right: 48px;
        padding-left: 48px;
      }
    }
  }

  .bg-el {
    position: absolute;

    &-1 {
      left: 66px;
      margin-top: 27px;
    }

    &-2 {
      right: 154px;
      margin-top: -40px;
    }

    &-3 {
      right: 343px;
      margin-top: 395px;
    }

    &-4 {
      right: 60px;
      margin-top: 49px;
    }

    &-5 {
      right: 37px;
      margin-top: -22px;
    }

    &-6 {
      right: 166px;
      margin-top: 218px;
    }

    &-7 {
      top: -80px;
      right: 0;
      border-top-right-radius: 24px;
    }

    &-8 {
      right: 0;
      bottom: 0;
      border-bottom-right-radius: 24px;
    }
  }

  .bg-wrap {
    position: absolute;
    width: calc(100% - $action-horizontal-offset);
    height: calc(100% - $action-vertical-offset);
  }

  footer {
    padding: 40px 80px;
  }

  &-mobile {
    .landing-body {
      margin-top: 68px;
    }

    .welcome-section {
      padding: 122px $horizontal-offset-mobile 0;
    }

    .welcome-section-content {
      margin: 0 $horizontal-offset-mobile;
    }

    .play-now-button {
      width: 100%;
    }

    .skills-section {
      padding: 60px $horizontal-offset-mobile;
    }

    .skills-section-content {
      :nth-child(2) {
        width: unset;
      }
    }

    .features-section {
      padding: 40px $horizontal-offset-mobile 80px;
    }

    .how-to-play-section {
      padding: 80px $horizontal-offset-mobile;

      .how-to-play-left-section {
        width: unset;
        max-width: 420px;
      }

      .game-demo-mp4-wrap {
        width: 100%;

        .game-demo-mp4 {
          width: auto;
        }
      }
    }

    .action-section {
      padding: 80px $horizontal-offset-mobile 32px;

      .action {
        padding: 80px 16px;

        a {
          width: auto;
        }
      }
    }

    .bg-el {
      position: absolute;

      &-1 {
        left: -12px;
        margin-top: -76px;
      }

      &-2 {
        right: 40px;
        margin-top: -120px;
      }

      &-3 {
        right: 122px;
        margin-top: 326px;
      }

      &-4 {
        right: 12px;
        margin-top: 70px;
      }

      &-5 {
        right: 26px;
        margin-top: 116px;
      }

      &-6 {
        right: 92px;
        margin-top: 334px;
      }
    }

    .bg-wrap {
      width: calc(100% - $action-horizontal-offset-mobile);
    }

    footer {
      padding: 40px $horizontal-offset-mobile;
    }
  }
}

.link {
  color: var(--primary-main);
  text-decoration: none;
}

@media (max-width: 1800px) {
  .landing-page .how-to-play-section .game-demo-mp4 {
    width: 700px;
  }
}

@media (max-width: 1600px) {
  .landing-page .how-to-play-section .game-demo-mp4 {
    width: 600px;
  }
}

@media (max-width: 1200px) {
  .landing-page .how-to-play-section .game-demo-mp4 {
    width: 500px;
  }
}

@media (max-width: 960px) {
  .landing-page .how-to-play-section .how-to-play-section-internal {
    flex-direction: column;
  }
}

@media (max-width: 348px) {
  .landing-page-mobile .skills-section-content:nth-child(1)svg {
    margin-bottom: 0;
  }
}
