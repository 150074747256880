@use 'common';

.modal :global {
  .MuiPaper-root {
    width: 480px;
    margin: 16px;
  }
}

.header {
  padding: 24px;
  color: var(--global-white);
  background-color: var(--primary-main);

  &.warning {
    background-color: var(--danger-main);
  }

  .close-icon {
    position: absolute;
    right: 22px;
  }
}

.body.body {
  z-index: 1;
  padding: 24px 24px 16px;

  :global {
    .MuiTypography-root {
      color: var(--text-primary);
    }
  }
}

.footer {
  z-index: 1;
  padding: 24px;

  &.centered {
    justify-content: center;

    button {
      width: 100px;
    }
  }
}

.link {
  color: var(--primary-main);
  text-decoration: none;
}

.confetti {
  position: absolute;
  bottom: 12px;
  left: 24px;
  width: 140px;
}
