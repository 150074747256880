.item {
  gap: 8px;
  padding: 12px;
  color: var(--primary-main);
  background-color: var(--primary-lightest);
  border-radius: 24px;
  outline: 2px solid transparent;

  &.expanded {
    width: 180px;
  }

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: transparent;
    border-radius: 33px;
  }

  .title {
    margin: 0;

    span {
      padding: 0 16px;
    }
  }

  svg {
    width: 24px;
    fill: var(--primary-main);
  }

  &.active {
    color: var(--global-white);
    background-color: var(--primary-main);

    .icon-wrap {
      background-color: var(--global-white);
    }

    &:hover {
      background-color: var(--primary-main);
      outline-color: transparent;
    }
  }

  &.last-item {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--primary-lightest);
    outline-color: var(--primary-light);
  }
}

.item-wrap {
  cursor: pointer;
}
