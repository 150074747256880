$brand-icon-height: 32px;
$bottom-bar-height: 72px;
$bottom-bar-height-with-play-button: 103px;
$mobile-horizontal-offset: 16px;
$mobile-top-notch-height: 103px;

.page-wrap {
  padding: 40px 32px 32px;

  &-mobile {
    margin-bottom: $bottom-bar-height-with-play-button;
    padding: 16px;

    &-with-notch {
      margin-top: $mobile-top-notch-height;
    }
  }
}

.game-wrap {
  height: 100vh;
}

.loader-wrap {
  align-items: center;
  justify-content: center;
  height: inherit;
}

.game-loader-wrap {
  position: absolute;
  top: 0;
  width: inherit;
  height: 100%;
}

.text-button {
  width: fit-content;
  min-width: unset;
  padding: 2px 1px 3px;
  color: var(--primary-main);
  border-radius: 0;
  transition: none;

  &:hover {
    padding-bottom: 1px;
    background: none;
    border-bottom: 2px var(--primary-light) solid;
  }
}

.brand-icon {
  height: $brand-icon-height;
}

.action-button {
  margin: 8px 0;
  padding: 16px;
  color: var(--global-white);
  background-color: var(--primary-main);
  border-bottom: 7px solid var(--primary-dark);
  border-radius: 24px;
  box-shadow: none;

  &.tiny {
    border-bottom: 3px solid var(--primary-dark);

    &:hover {
      border-bottom: 3px solid var(--text-primary);
      box-shadow: none;
    }

    &.inverse {
      color: var(--primary-main);
      background-color: var(--global-white);
      border-color: var(--primary-dark);

      &:hover {
        color: var(--primary-dark);
      }
    }
  }

  &.mobile {
    padding: 8px;
  }

  &.warning {
    background-color: var(--danger-main);
    border-bottom: 7px solid var(--danger-dark);

    &:hover {
      background-color: var(--danger-main);
    }
  }

  &:hover {
    background-color: var(--primary-main);
    border-bottom: 7px solid var(--text-primary);
    box-shadow: none;
  }

  &:global(.Mui-disabled) {
    background-color: var(--primary-main);
  }

  :global(.MuiLoadingButton-loadingIndicator) {
    color: var(--global-white);
  }
}

.secondary-button {
  margin: 8px 0;
  padding: 16px;
  color: var(--primary-main);
  border-radius: 24px;

  &:hover {
    color: var(--primary-dark);
    box-shadow: none;
  }
}

.outlined-button {
  margin: 8px 0;
  padding: 16px;
  color: var(--primary-main);
  background-color: var(--global-white);
  border: 1px solid var(--primary-main);
  border-radius: 24px;

  &:hover {
    color: var(--primary-dark);
    background-color: var(--primary-light);
    box-shadow: none;
  }
}

.freeze {
  overflow: hidden !important;
}

.mobile-height {
  height: 100%;
}
