.burger {
  height: 22.5px;
  cursor: pointer;

  :nth-child(1) {
    transition: ease-out 0.4s;
  }

  :nth-child(2) {
    transition: ease-out 0.4s;
  }

  :nth-child(3) {
    transition: ease-out 0.4s;
  }

  &-opened {
    :nth-child(1) {
      transform: rotate(-45deg) translate(-7px, 7px);
      transition: ease-out 0.4s;
    }

    :nth-child(2) {
      opacity: 0;
      transition: none;
    }

    :nth-child(3) {
      transform: rotate(45deg) translate(-6px, -7px);
      transition: ease-out 0.4s;
    }
  }
}

.burger-bar {
  width: 30px;
  height: 3.5px;
  background-color: var(--primary-main);
  border-radius: 2px;
}
