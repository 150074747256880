.horizontal-carousel-wrap {
  width: inherit;
}

.horizontal-carousel-root {
  position: relative;
  display: flex;
  align-items: center;
  width: inherit;
  height: inherit;
  overflow-x: auto;

  .horizontal-carousel-list {
    position: absolute;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
    transition-property: transform;

    &-empty {
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      width: inherit;
    }
  }

  .horizontal-carousel-img {
    box-sizing: border-box;
    height: 100%;
    padding: 2px;
    border: 1px solid var(--gray-bg-3);
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.75;

    &:hover {
      border: 1px solid var(--gradient-4-light);
      opacity: 1;
    }

    &-selected {
      padding: 0;
      border: 3px solid var(--hex-4-dark);
      opacity: 1;

      &:hover {
        border: 3px solid var(--hex-4-dark);
      }
    }
  }
}

.buttons-wrap {
  position: relative;
  bottom: 50%;

  .horizontal-carousel-arrow {
    position: absolute;
    padding: 0;
    background: var(--primary-light);
    border: 2px solid var(--global-white);
    transform: translateY(-50%) rotate(270deg);

    &.left-arrow {
      left: -20px;
      transform: translateY(-50%) rotate(90deg);
    }

    &.right-arrow {
      right: -20px;
    }

    svg {
      width: 50%;
      height: 50%;
      margin-top: 1px;
      fill: var(--primary-main);
    }

    &:active {
      border-color: var(--primary-main);
    }
  }
}

@media (hover: hover) {
  .buttons-wrap {
    .horizontal-carousel-arrow:hover {
      border-color: var(--primary-main);
    }
  }
}
