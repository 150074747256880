@use '../../assets/styles/common';

:global {
  .MuiPaper-root.MuiDrawer-paper {
    width: 94px;
    overflow-x: hidden;
    border: none;
    box-shadow: 0 10px 20px 2.5px rgb(24 24 27 / 5%), 0 5px 10px 1.25px rgb(24 24 27 / 6%);
    transition: width 0.3s ease-in-out;
  }
}

.sideBar {
  display: flex;
}

.sideBar-paper {
  height: inherit;
  padding: 24px 14px;
  transition: padding 0.3s ease-in-out;
}

.items {
  margin-bottom: 30px;
}

.game-items-list {
  gap: 16px;
  align-items: self-start;
  padding: 24px 0;

  &.collapsed {
    padding-top: 61px;
  }
}

.list-item-button {
  margin-left: 10px;
  padding: 0;
  cursor: pointer;
  transition: margin-left 0.3s ease-in-out;

  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }

  :global(.MuiTouchRipple-root) {
    display: none;
  }
}

.loader-wrap {
  display: flex;
  justify-content: center;
}

.name {
  display: flex;
  margin-bottom: 20px;
  padding-left: 2px;
  color: var(--text-secondary);
}

.toggle-button {
  position: fixed;
  bottom: 98px;
  left: 78px;
  z-index: 1201;
  width: 32px;
  height: 32px;
  padding: 0;
  background: var(--primary-lightest);
  border: 2px solid var(--global-white);
  border-radius: 20px;
  transition: left 0.3s ease-in-out;

  &:hover {
    color: var(--global-white);
    background-color: var(--primary-main);
  }
}

.open {
  :global(.MuiPaper-root.MuiDrawer-paper) {
    width: 214px;
  }

  .sideBar-paper {
    padding-left: 24px;
  }

  .toggle-button {
    left: 199px;
  }

  .list-item-button {
    margin-left: 0;
  }
}

.game-modes-title {
  color: var(--text-secondary);
}

.sideBar-footer {
  min-height: 117px;
}
