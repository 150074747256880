@use '../../assets/styles/common' as *;

.bottom-bar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  height: $bottom-bar-height;
  padding: 16px;
  background-color: var(--global-white);
  border-top: 1px solid var(--gray-bg-2);
  box-shadow: 0 4px 15px 0 rgb(102 102 102 / 16%);

  .icon-button {
    width: 96px;
    color: var(--text-primary);

    svg {
      width: 26px;
      fill: var(--text-primary);
    }

    &.active {
      color: var(--primary-main);

      svg {
        fill: var(--primary-main);
      }
    }

    &.disabled {
      opacity: 0.38;
    }

    &:active {
      color: var(--primary-dark);

      svg {
        fill: var(--primary-dark);
      }
    }
  }

  .play-icon-button {
    width: 96px;
    margin-top: -44px;
    color: var(--text-primary);

    .play-icon-wrap {
      padding: 14px;
      background-color: var(--primary-main);
      border: 4px solid var(--global-white);
      border-radius: 50px;

      svg {
        width: 26px;
        fill: var(--global-white);
      }
    }

    &:active {
      color: var(--primary-dark);

      .play-icon-wrap {
        background-color: var(--primary-dark);
      }
    }
  }

  .bottom-navigation {
    position: absolute;
    bottom: -500px;
    width: 100%;
    overflow: auto;
    background-color: var(--global-white);
    border-radius: 32px 32px 0 0;
    box-shadow: 0 10px 20px 2.5px rgb(24 24 27 / 10%), 0 5px 10px 1.25px rgb(24 24 27 / 11%);
    transition: bottom 0.3s ease-in-out;

    &-open {
      bottom: 0;
    }

    .dragger {
      width: inherit;
      padding: 16px 0 12px;
      cursor: pointer;

      .dragger-line {
        width: 100px;
        height: 8px;
        background: var(--gray-bg-3);
        border-radius: 4px;
      }
    }

    .login-logout-wrap {
      width: 100%;
      padding: 32px 0;

      button {
        padding: 0;
      }

      .login-button {
        color: var(--primary-main);

        svg {
          width: 24px;
          fill: var(--primary-main);
        }
      }

      .logout-button {
        color: var(--text-lightest);

        svg {
          width: 24px;
          fill: var(--text-lightest);
        }
      }
    }
  }
}
