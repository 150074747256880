.game-color-chip {
  width: 20px;
  height: 20px;
  border-radius: 8px;
}

.total-games {
  position: absolute;
  top: 66px;

  &.narrow {
    top: 74px;
  }
}

.games-pie-chart {
  height: 200px;
}

.computer-grade-pie-chart {
  height: 100px;
}

.widget {
  position: relative;
  padding: 32px;
  background-color: var(--gray-bg-1);
  border-radius: 32px;

  &-mobile {
    padding: 24px;
  }

  h1,
  h3,
  span {
    z-index: 1;
  }
}

.cards-icon {
  position: absolute;
  right: 32px;
  bottom: 32px;

  &-mobile {
    right: 24px;
    bottom: 0;
    width: 120px;
    height: 100%;
  }
}

.computer-grade {
  position: absolute;
  top: 44px;
  width: 100%;
  text-align: center;
}
