.item {
  gap: 8px;
  align-self: start;
  height: 24px;
  padding: 0 0 0 21px;
  color: var(--primary-main);
  cursor: pointer;
  transition: padding-left 0.3s ease-in-out;

  &.expanded {
    padding: 0;
  }

  svg {
    width: 24px;
    fill: var(--primary-main);
  }

  &:hover {
    color: var(--primary-dark);
    background-color: transparent;

    svg {
      fill: var(--primary-dark);
    }
  }
}

.title {
  text-wrap: nowrap;
}
