.players-panel-wrap {
  padding: 20px;
}

.players-panel {
  box-sizing: border-box;
  width: stretch;
  height: 73px;
  padding: 17px 20px;
  background: var(--primary-light);
  border-radius: 24px;
}

.pointer {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.5rem;
  transition: left 0.3s ease-in-out;
}
