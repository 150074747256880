@use '../../assets/styles/common' as *;

$game-settings-wrap-gap: 40px;

.loader-wrap {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.game-settings-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: inherit;
  padding: 0 16px;
  background-color: var(--gray-bg-2);
}

.game-settings-loader-wrap {
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - $mobile-top-notch-height - 2 * $game-settings-wrap-gap);
  margin-top: $mobile-top-notch-height + $game-settings-wrap-gap;
}

.game-settings-form {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding-top: $mobile-top-notch-height + $game-settings-wrap-gap;
  padding-bottom: $bottom-bar-height-with-play-button;
}

.start-game-button {
  align-self: center;
  width: 100%;
  margin-bottom: 40px;
}

.form-wrap {
  width: calc(100% - $mobile-horizontal-offset * 2);
  padding: 16px;
  background: var(--gray-bg-1);
  border-radius: 16px;

  .arrow-drop-down-icon {
    right: 12px;
    width: 10px;
    fill: var(--gray-icon);
  }

  .dropdown {
    width: stretch;
  }

  :global {
    .MuiInputBase-root.MuiInputBase-root {
      padding: 0;
      background: var(--global-white);
      border: 1px solid var(--gray-bg-3);
      border-radius: 8px;

      input {
        padding: 7px 4px 7px 12px;
      }
    }

    .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
      right: 9px;
      display: flex;
      height: calc(100% - 6px);

      svg {
        width: 10px;
        fill: var(--gray-icon);
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiButtonBase-root {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.computer-grade {
  width: 100%;
}

.computer-grade-slider-wrap {
  height: 14px;
  padding: 0 7px;
  background-color: var(--global-white);
  border: 1px solid var(--gray-bg-3);
  border-radius: 7px;
}

.computer-grade-slider {
  :global {
    .MuiSlider-rail {
      height: 8px;
      padding-right: 4px;
      background: var(--primary-light);
      border-radius: 8px;
    }

    .MuiSlider-track {
      height: 8px;
      margin-left: -4px;
      background-color: var(--primary-main);
      border: 0;
    }

    .MuiSlider-thumb {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }

    .MuiSlider-mark {
      width: 2px;
      height: 4px;
      background-color: var(--global-white);
      border-radius: 0;
      opacity: 1;
    }
  }
}

.custom-paper {
  :global {
    .MuiAutocomplete-listbox {
      overflow: unset;
    }
  }
}

.cards-number {
  height: 39px;
  font-size: 16px;

  svg {
    width: 10px;
    margin-top: 3px;
    fill: var(--gray-icon);
  }

  :global {
    .MuiSelect-select {
      padding: 0 0 0 12px;
    }
  }
}

.setting-head {
  svg {
    width: 16px;
    height: 16px;
    fill: var(--gray-icon);

    &:hover {
      fill: var(--primary-main);
    }
  }
}

.player-settings {
  width: stretch;

  .add-player-button :global {
    .MuiButton-startIcon {
      margin-left: 0;
    }
  }

  .player-inputs-wrap {
    flex-wrap: wrap;
    width: inherit;

    .player-input {
      flex: 0 0 calc(50% - 8px);

      :global {
        .MuiInputBase-root {
          padding: 0 8px 0 12px;

          input {
            padding: 7px 0;
          }
        }

        .MuiInputAdornment-root {
          color: unset;
        }
      }
    }
  }

  .add-player-button {
    color: var(--primary-main);

    svg {
      font-size: 24px;
    }

    &:hover {
      color: var(--primary-main);

      svg {
        font-size: 24px;

        :last-child {
          stroke: var(--primary-main);
        }
      }
    }
  }

  .remove-player-button {
    margin-right: 0;

    svg:hover {
      fill: var(--input-error);
    }
  }
}
