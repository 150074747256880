@use '../../assets/styles/common';

$footer-height: 61px;

.info {
  box-sizing: border-box;
  max-width: 669px;
  max-height: calc(100% - $footer-height);
}

.title {
  align-items: center;
  color: var(--text-primary);

  svg {
    width: 24px;
    fill: var(--primary-main);
  }
}

.opening-methods-items {
  flex-wrap: wrap;
  text-wrap: nowrap;
}

.opening-method-type {
  margin-right: 14px;
}

.game-structure-title.game-structure-title {
  margin-bottom: 16px;
}

.copyright.copyright {
  position: fixed;
  box-sizing: border-box;
  max-width: 363px;
  margin-right: 32px;
  padding: 24px;
  text-align: center;
  background-color: var(--primary-lightest);
  border-radius: 24px;

  .author {
    align-self: center;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}

.accordion-item.accordion-item {
  padding: 24px;
  border: 2px solid var(--gray-bg-2);
  border-radius: 24px;

  :global {
    .MuiCollapse-wrapper {
      padding-top: 12px;
    }
  }
}

.link {
  color: var(--primary-main);
  text-decoration: none;
}
