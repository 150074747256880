.horizontal-carousel-infinite-auto-wrap {
  overflow: hidden;
}

.horizontal-carousel-infinite-auto {
  width: fit-content;
}

.icon-wrap {
  position: relative;

  .icon-title {
    position: absolute;
    top: 32px;
    left: 32px;
    color: var(--global-white);
  }
}
