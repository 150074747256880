.widget {
  position: relative;
  width: 100%;
  color: var(--global-white);
  border-radius: 24px;
  cursor: pointer;

  .title {
    position: absolute;
    top: 32px;
    left: 32px;
    width: inherit;
  }

  svg {
    height: 100%;
  }

  &:hover {
    box-shadow: 0 4px 13px 0 rgb(40 83 106 / 30%);
  }
}

.mobile-widget {
  width: 100%;
  height: 88px;
  color: var(--text-primary);
  text-decoration: none;
  background: var(--gray-bg-1);
  border: 1px solid var(--gray-bg-2);
  border-radius: 24px;

  svg {
    width: 35%;
    min-width: 135px;
    height: 100%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}

@media (min-width: 1200px) {
  .widget.offset {
    padding-right: 44px;
  }
}

@media (min-width: 1500px) {
  .widget.offset {
    padding-right: 80px;
  }
}
