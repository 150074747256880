@use '../../assets/styles/common';

.wrap {
  position: fixed;
  bottom: 0;
  z-index: 11111;
  width: stretch;
  height: 78px;
  padding: 5px 20px;
  color: var(--global-white);
  background-color: var(--text-secondary);

  .accept-button {
    width: max-content;
    min-width: 140px;
  }

  .close-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  &-mobile {
    height: auto;
    padding: 16px 16px 5px;

    .accept-button {
      width: 100%;
    }
  }
}

.link {
  color: var(--global-white);
}
