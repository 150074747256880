// 64 x 89
$card-ratio: 0.7191;
$card-width: 200px;
$card-height: calc($card-width / $card-ratio);

.card {
  position: relative;
  max-width: $card-width;
  max-height: $card-height;
  margin-right: 14px;
  margin-bottom: 14px;
  background-color: var(--card-background);
  border-radius: 18px;
  box-shadow: 1px 1px 3px var(--card-shadow);
  transform-style: preserve-3d;
  cursor: pointer;
  transition: transform 0.5s;

  &.mobile {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &.flipped {
    transform: rotateY(180deg);
  }

  .card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    .card-overlay {
      position: absolute;
      width: inherit;
      height: inherit;

      &.correct {
        background-color: rgb(0 128 0 / 25%);
      }

      &.not-correct {
        background-color: rgb(255 10 39 / 25%);
      }
    }
  }

  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
    backface-visibility: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
