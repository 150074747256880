@use '../../assets/styles/common' as *;

.mobile-top-notch {
  position: fixed;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: $mobile-top-notch-height;
  min-height: $mobile-top-notch-height;
  padding-bottom: 10px;
  color: var(--text-primary);
  background-color: var(--primary-light);

  &-back-button {
    position: absolute;
    left: 10px;
    margin-bottom: -4px;
  }
}
