@use '../../assets/styles/common';

.input-box {
  :global {
    .MuiInputBase-root {
      border-radius: 12px;
    }

    .MuiInputBase-input {
      min-height: 20px;
    }

    .MuiInputAdornment-root {
      margin-left: -38px;
    }
  }
}

.container {
  height: 100vh;
}

.wrap {
  box-sizing: border-box;
  width: 100%;
  max-width: 440px;

  &-mobile {
    padding: 16px;
  }
}

.link {
  display: flex;
}

.logo {
  cursor: pointer;
}

.google-button {
  padding: 16px 0;
  background-color: var(--gray-bg-2);
  border-radius: 24px;

  &:hover {
    background-color: var(--primary-light);
  }
}

.divider {
  align-items: flex-start;

  &::before {
    border-color: var(--gray-bg-3);
  }

  &::after {
    border-color: var(--gray-bg-3);
  }

  :global(.MuiDivider-wrapper) {
    padding: 0 16px;
  }
}

.illustration-section {
  height: 100%;
  background-color: var(--primary-main);
  border-radius: 0;

  .small-star {
    position: absolute;
    bottom: 0;
    width: 12%;
  }

  .big-star {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
  }

  .illustration {
    position: absolute;
    bottom: 0;
    width: 50%;
    max-width: 754px;
  }

  .illustration-text-wrap {
    position: absolute;
    top: 148px;
    align-items: center;
    width: stretch;
    color: var(--global-white);
  }

  .illustration-text {
    align-items: center;
    max-width: 500px;
    text-align: center;
  }
}
