.MuiTooltip-tooltip {
  padding: 4px 12px;
  color: var(--global-white);
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.266px;
  background-color: var(--text-lightest);
  border-radius: 8px;
}

.MuiTooltip-arrow {
  color: var(--text-lightest);
  font-size: 20px;
}

.MuiTooltip-popper[data-popper-placement*='right'] .MuiTooltip-arrow {
  top: -1px !important;
  left: 2px;
}

.MuiChip-root {
  height: 26px;
}

.MuiAccordionSummary-root {
  min-height: 36px;

  &.Mui-expanded {
    min-height: 36px;
  }
}

.MuiAccordionSummary-content {
  margin: 0;

  &.Mui-expanded {
    margin: 0;
  }
}

.MuiAccordion-root.MuiAccordion-root {
  margin: 0;

  &.Mui-expanded {
    margin: 0;
  }
}

.MuiPaper-root {
  box-shadow: none;

  &::before {
    height: 0;
  }
}

.MuiAutocomplete-popper .MuiPaper-root {
  border-radius: 8px;
}

.MuiAutocomplete-option {
  height: 32px;

  &[aria-selected='true'] {
    background-color: var(--dd-menu-item-light) !important;
    border-left: solid 3px var(--primary-main);
  }
}

.MuiList-root .MuiButtonBase-root {
  height: 32px;

  &.Mui-selected {
    background-color: var(--dd-menu-item-light);
    border-left: solid 3px var(--primary-main);

    :nth-child(1) {
      margin-left: -1.5px;
    }
  }
}

.MuiMenu-paper {
  margin-top: 5px;
  transition-duration: 0s !important;
}

.MuiTableCell-root {
  padding: 12px 20px;
  text-align: left;
}

.MuiTableBody-root .MuiTableCell-root {
  padding: 16px 20px;
}
